// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-experience-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-education-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/education.js" /* webpackChunkName: "component---src-templates-education-js" */),
  "component---src-templates-games-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/games.js" /* webpackChunkName: "component---src-templates-games-js" */),
  "component---src-templates-movies-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/movies.js" /* webpackChunkName: "component---src-templates-movies-js" */),
  "component---src-templates-pizza-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/pizza.js" /* webpackChunkName: "component---src-templates-pizza-js" */),
  "component---src-templates-projects-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-skills-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/skills.js" /* webpackChunkName: "component---src-templates-skills-js" */),
  "component---src-templates-subaru-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/templates/subaru.js" /* webpackChunkName: "component---src-templates-subaru-js" */),
  "component---src-pages-education-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/education/index.js" /* webpackChunkName: "component---src-pages-education-index-js" */),
  "component---src-pages-experience-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/experience/index.js" /* webpackChunkName: "component---src-pages-experience-index-js" */),
  "component---src-pages-games-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movies-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/movies/index.js" /* webpackChunkName: "component---src-pages-movies-index-js" */),
  "component---src-pages-pizza-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/pizza/index.js" /* webpackChunkName: "component---src-pages-pizza-index-js" */),
  "component---src-pages-projects-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-skills-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/skills/index.js" /* webpackChunkName: "component---src-pages-skills-index-js" */),
  "component---src-pages-subaru-index-js": () => import("/Users/levilewis/Projects/levilew.is/website/src/pages/subaru/index.js" /* webpackChunkName: "component---src-pages-subaru-index-js" */)
}

exports.data = () => import("/Users/levilewis/Projects/levilew.is/website/.cache/data.json")

